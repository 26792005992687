import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { INotificationState, Notification } from './notifications.type';

export const initialState: INotificationState = {
  notifications: {},
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications(state, { payload }: PayloadAction<Notification[]>) {
      if (payload) {
        const notifications: Record<string, Notification> = {}
        payload.map(n => {
          const notif = n;
          notif.seen = state.notifications[n.id] !== undefined ? state.notifications[n.id].seen : false;
          notifications[n.id] = notif;
        })
        state.notifications = notifications
      }
      else {
        state.notifications = {}
      }
    },
    setSeenNotification(state, { payload }: PayloadAction<string>) {
      state.notifications[payload].seen = true;
    }
  },
});

export const { setNotifications, setSeenNotification } = notificationsSlice.actions;

export const notificationsSelector = (state: RootState): INotificationState => state.notifications;

export const selectNotifications = createSelector(notificationsSelector, (state) => state.notifications);