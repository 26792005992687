import React from 'react';
import { RouteObject } from 'react-router-dom';

import { suspense } from './utils';

const App                         = React.lazy(() => import('../App/App')); // prettier-ignore
const TextTranslation             = React.lazy(() => import('../pages/Application/TextTranslation/TextTranslation')); // prettier-ignore
const MachineTranslation          = React.lazy(() => import('../pages/Application/MachineTranslation/MachineTranslation')); // prettier-ignore
const NotFound                    = React.lazy(() => import('../pages/NotFound/NotFound')); // prettier-ignore
const History                     = React.lazy(() => import('../pages/Application/History/History')); // prettier-ignore
const MyProfile                   = React.lazy(() => import('../pages/Administration/UserProfile/UserProfile')); // prettier-ignore
const SpeechToText                = React.lazy(() => import('../pages/Application/SpeechToText/SpeechToText')); // prettier-ignore
const SpeechHistory               = React.lazy(() => import('../pages/Application/SpeechHistory/SpeechHistory')); // prettier-ignore
const Help                        = React.lazy(() => import('../pages/Application/Help/Help')); // prettier-ignore
const TableExtractor              = React.lazy(() => import('../pages/Application/TableExtractor/TableExtractor')); // prettier-ignore
const TableExtractorHistory       = React.lazy(() => import('../pages/Application/TableExtractorHistory/TableExtractorHistory')); // prettier-ignore
const TranslationGlobalService    = React.lazy(() => import('../pages/TranslationGlobalService')); // prettier-ignore
const TableExtractorGlobalService = React.lazy(() => import('../pages/TableExtractorGlobalService')); // prettier-ignore
const SpeechToTextGlobalService   = React.lazy(() => import('../pages/SpeechToTextGlobalService')); // prettier-ignore
const DocumentAnalyser            = React.lazy(() => import('../pages/Application/DocumentAnalyser/LcLlm')); // prettier-ignore
const TMManager                   = React.lazy(() => import('../pages/Application/TMManager/TMManager')); // prettier-ignore
const KnowledgeManagementCentre   = React.lazy(() => import('../pages/Application/KnowledgeManagement/KnowledgeManagement')); // prettier-ignore
const Company                     = React.lazy(() => import('../pages/Administration/Company/Company')); // prettier-ignore
const HelpAdministration          = React.lazy(() => import('../pages/Administration/HelpAdministration/HelpAdministration')); // prettier-ignore

export interface AppRoute<P extends unknown[] = never[]> {
  name: string;
  path: (...args: P) => string;
}

function typeRoutes<T extends Record<string, AppRoute>>(
  routes: T
): { [K in keyof T]: T[K] } {
  return routes;
}

export const routes = typeRoutes({
  TranslationGlobalService: {
    name: '',
    path() {
      return '/';
    },
  },
  MachineTranslation: {
    name: '',
    path() {
      return '/';
    },
  },
  TextTranslation: {
    name: 'TextTranslation',
    path() {
      return '/TextTranslation';
    },
  },
  History: {
    name: 'History',
    path() {
      return '/History';
    },
  },
  TMManager: {
    name: 'TMManager',
    path() {
      return '/TMManager';
    },
  },
  SpeechToTextGlobalService: {
    name: 'SpeechToText',
    path() {
      return '/SpeechToText';
    },
  },
  SpeechToText: {
    name: '',
    path() {
      return '/SpeechToText';
    },
  },
  SpeechHistory: {
    name: 'History',
    path() {
      return '/SpeechToText/History';
    },
  },
  TableExtractorGlobalService: {
    name: 'TableExtractor',
    path() {
      return '/TableExtractor';
    },
  },
  TableExtractor: {
    name: '',
    path() {
      return '/TableExtractor';
    },
  },
  TableExtractorHistory: {
    name: 'History',
    path() {
      return '/TableExtractor/History';
    },
  },
  DocumentAnalyser: {
    name: 'DocumentAnalyser',
    path() {
      return '/DocumentAnalyser';
    }
  },
  KnowledgeManagementCentre: {
    name: 'KnowledgeManagementCentre',
    path() {
      return '/KnowledgeManagementCentre';
    }
  },
  Help: {
    name: 'Help',
    path() {
      return '/help';
    },
  },
  MyProfile: {
    name: '/Administration/MyProfile',
    path() {
      return '/Administration/MyProfile';
    },
  },
  Company: {
    name: '/Administration/Company',
    path() {
      return '/Administration/Company';
    },
  },
  HelpAdministration: {
    name: '/Administration/Help',
    path() {
      return '/Administration/Help';
    },
  },
});

export const routerRoutes: RouteObject[] = [
  {
    element: suspense(App),
    children: [
      {
        path: routes.TranslationGlobalService.name,
        element: suspense(TranslationGlobalService),
        children: [
          {
            path: routes.MachineTranslation.name,
            element: suspense(MachineTranslation),
          },
          {
            path: routes.TextTranslation.name,
            element: suspense(TextTranslation),
          },
          {
            path: routes.History.name,
            element: suspense(History),
          },
          {
            path: routes.TMManager.name,
            element: suspense(TMManager),
          },
        ],
      },
      {
        path: routes.SpeechToTextGlobalService.name,
        element: suspense(SpeechToTextGlobalService),
        children: [
          {
            path: routes.SpeechToText.name,
            element: suspense(SpeechToText),
          },
          {
            path: routes.SpeechHistory.name,
            element: suspense(SpeechHistory),
          },
        ],
      },
      {
        path: routes.TableExtractorGlobalService.name,
        element: suspense(TableExtractorGlobalService),
        children: [
          {
            path: routes.TableExtractor.name,
            element: suspense(TableExtractor),
          },
          {
            path: routes.TableExtractorHistory.name,
            element: suspense(TableExtractorHistory),
          },
        ],
      },
      {
        path: routes.DocumentAnalyser.name,
        element: suspense(DocumentAnalyser),
      },
      {
        path: routes.KnowledgeManagementCentre.name,
        element: suspense(KnowledgeManagementCentre),
      },
      {
        path: routes.MyProfile.name,
        element: suspense(MyProfile),
      },
      {
        path: routes.Company.name,
        element: suspense(Company),
      },
      {
        path: routes.Help.name,
        element: suspense(Help),
      },
      {
        path: routes.HelpAdministration.name,
        element: suspense(HelpAdministration),
      },
    ],
  },
  {
    path: '*',
    element: suspense(NotFound),
  },
];
