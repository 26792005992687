export enum LCUserRole {
  Admin = 'admin',
  // TMManager = 'tmmanager',
  User = 'user',
}

export interface Service {
  name: string;
  expiration_date: number | null;
  limits: {
    used: number;
    max: number | null;
  };
}

export interface UserCompany {
  id: string;
  name: string;
  contact: string | null | undefined;
  parent?: string;
}

export interface UserProfile {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  group: string;
  group_tree: UserCompany[];
}

export interface UserInfo extends UserProfile{
  role: LCUserRole;
  services: Service[];
}

export interface IUserInfoState {
  profile: UserProfile;
  role: LCUserRole;
  services: Record<string, Service>;
}