import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Language } from '../../services/translation';
import { RootState } from '../store';
import { ILanguageState } from './language.types';

export const initialState: ILanguageState = {
  lastTextTranslationSourceLanguage: undefined,
  lastTextTranslationTargetLanguage: undefined,
  lastDocumentTranslationSourceLanguage: undefined,
  lastDocumentTranslationTargetLanguage: undefined,
  lastSpeechToTextSourceLanguage: undefined,
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLastTextTranslationSourceLanguage(
      state,
      { payload }: PayloadAction<Language | undefined>
    ) {
      state.lastTextTranslationSourceLanguage = payload;
    },
    setLastTextTranslationTargetLanguage(
      state,
      { payload }: PayloadAction<Language | undefined>
    ) {
      state.lastTextTranslationTargetLanguage = payload;
    },
    setLastDocumentTranslationSourceLanguage(
      state,
      { payload }: PayloadAction<Language | undefined>
    ) {
      state.lastDocumentTranslationSourceLanguage = payload;
    },
    setLastDocumentTranslationTargetLanguage(
      state,
      { payload }: PayloadAction<Language[] | undefined>
    ) {
      state.lastDocumentTranslationTargetLanguage = payload;
    },
    setLastSpeechToTextSourceLanguage(
      state,
      { payload }: PayloadAction<Language | undefined>
    ) {
      state.lastSpeechToTextSourceLanguage = payload;
    },
  },
});

export const {
  setLastTextTranslationSourceLanguage,
  setLastTextTranslationTargetLanguage,
  setLastDocumentTranslationSourceLanguage,
  setLastDocumentTranslationTargetLanguage,
  setLastSpeechToTextSourceLanguage,
} = languageSlice.actions;

export const languageSelector = (state: RootState): ILanguageState =>
  state.language;

export const selectLastTextTranslationSourceLanguage = createSelector(
  languageSelector,
  (language) => language.lastTextTranslationSourceLanguage
);
export const selectLastTextTranslationTargetLanguage = createSelector(
  languageSelector,
  (language) => language.lastTextTranslationTargetLanguage
);
export const selectLastDocumentTranslationSourceLanguage = createSelector(
  languageSelector,
  (language) => language.lastDocumentTranslationSourceLanguage
);
export const selectLastDocumentTranslationTargetLanguage = createSelector(
  languageSelector,
  (language) => language.lastDocumentTranslationTargetLanguage
);
export const selectLastSpeechToTextSourceLanguage = createSelector(
  languageSelector,
  (language) => language.lastSpeechToTextSourceLanguage
);
