import { iStore, NOTIFICATION_TYPE} from "react-notifications-component/dist/src/typings";

export const addNotification = (store: iStore, title: string, message: string, type: NOTIFICATION_TYPE) => {
  store.addNotification({
    title: `${title}`,
    message: `${message}`,
    type: `${type}`,
    container: 'bottom-right',
    animationIn: ['animate__animated animate__fadeIn'],
    animationOut: ['animate__animated animate__fadeOut'],
    dismiss: {
      duration: 10000,
      onScreen: true,
      pauseOnHover: true,
      showIcon: true,
    },
  });
}

export const throwError = (error: any) => {
  if (error.response) {                           // eslint-disable-line
    if (error.response.data) {                    // eslint-disable-line
      throw new Error(error.response.data)        // eslint-disable-line
    }
    else {
      throw new Error(error.response.statusText)  // eslint-disable-line
    }
  }
  else {
    throw new Error('Unknown Error')
  }
}
