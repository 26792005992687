import { useKeycloak } from '@react-keycloak/web';
import axios, { Axios } from 'axios';
import { createContext, FC, useContext, useEffect, useState } from 'react';

export interface AppConfig {
  api: string;
}

export interface LCAPIAxiosContextValue {
  axios: Axios;
  token?: string;
}

export const LCAPIAxiosContext = createContext<LCAPIAxiosContextValue>(
  null as unknown as LCAPIAxiosContextValue
);

/**
 * this context provider provides an axios instance with an interceptor
 * setting the baseUrl and token for each request
 **/
export const LCAPIAxiosProvider: FC = ({ children }) => {
  const { keycloak } = process.env.REACT_APP_USE_KEYCLOAK
    ? useKeycloak()
    : { keycloak: { token: 'mocktoken', onAuthRefreshSuccess() {}, logout() {}, updateToken() {} } // eslint-disable-line @typescript-eslint/no-empty-function
      };
  const [axiosInstance] = useState(() => axios.create());
  const [token, setToken] = useState(keycloak?.token);

  useEffect(() => {
    keycloak.onAuthRefreshSuccess = () => {
      setToken(keycloak?.token);
    };
    const interceptor = axiosInstance.interceptors.request.use(async function (config) {
      const minValidity = config.method === "post" ? process.env.REACT_APP_TOKEN_MIN_VALIDITY_POST : process.env.REACT_APP_TOKEN_MIN_VALIDITY_GET;
      await keycloak.updateToken(minValidity)!      // eslint-disable-line @typescript-eslint/no-non-null-assertion
      .then(()=>config.headers = {
          Authorization: `Bearer ${keycloak.token ? keycloak.token : ''}`,
        })!
      .catch(function() {
        void keycloak.logout()
      });
      return config;
    });
    return () => {
      delete keycloak.onAuthRefreshSuccess;
      axiosInstance.interceptors.request.eject(interceptor);
    };
  });

  useEffect(() => {
    const id = axiosInstance.interceptors.request.use((config) => {
      config.headers = {
        Authorization: `Bearer ${token ? token : ''}`,
      };
      return {
        ...config,
        baseURL: process.env.REACT_APP_API,
      };
    });
    return () => axiosInstance.interceptors.request.eject(id);
  }, [token]);

  return (
    <LCAPIAxiosContext.Provider value={{ axios: axiosInstance, token }}>
      {children}
    </LCAPIAxiosContext.Provider>
  );
};

interface IBuildUrlOptions {
  base?: string;
  path?: string;
  token?: string;
  queries?: [string, string][];
}
export type IBuildUrlOptionsWOBase = Omit<IBuildUrlOptions, 'token'>;

const _buildURL = ({
  base = process.env.REACT_APP_API,
  path = '',
  queries = [],
}: IBuildUrlOptions = {}): string => {
  const url = new URL(`${base}/${path}`);
  for (const [key, value] of queries) {
    url.searchParams.append(key, value);
  }
  return url.href;
};

export const useLCAPIAxios = (): {
  axios: Axios;
  buildURL: (url: IBuildUrlOptionsWOBase) => string;
} => {
  const { axios } = useContext(LCAPIAxiosContext);

  const buildURL = (url: IBuildUrlOptionsWOBase) => {
    return _buildURL({ ...url });
  };
  return { axios, buildURL };
};