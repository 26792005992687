import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IUserInfoState, LCUserRole, Service, UserProfile } from './user-info.types';

export const initialState: IUserInfoState = {
  profile: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    group: '',
    group_tree: [],
  },
  role: LCUserRole.User,
  services: {},
};

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setAvailableServices(state, { payload }: PayloadAction<Service[]>) {
      state.services = {};
      if (payload)
        payload.map(
          (service) => (state.services[service.name] = service)
        );
    },
    setRole(state, { payload }: PayloadAction<LCUserRole>) {
      if (payload) state.role = payload;
    },
    setProfile(state, { payload }: PayloadAction<UserProfile>) {
      if (payload) state.profile = payload;
    },
  },
});

export const { setAvailableServices, setRole, setProfile } = userInfoSlice.actions;

export const servicesSelector = (state: RootState): IUserInfoState => state.userInfo;

export const selectAvailableServices = createSelector(servicesSelector, (services) => services.services);
export const selectRole = createSelector(servicesSelector, (services) => services.role);
export const selectProfile = createSelector(servicesSelector, (services) => services.profile);
