export type DAChatId = string;

export interface DAFile {
  id: string;
  name: string;
  status: ExtractionStatus;
}

export interface DAChat {
  id: DAChatId;
  name: string;
  files: DAFile[];
  date: string;
  dialog: string[][];
  status: ExtractionStatus;
  onClick: () => void;
  onDelete: () => void;
  onUpdate: () => void;
}

export interface DAChatMessage {
  id: number;
  discussion_id: string;
  is_chatbot: boolean;
  rating: number;
  message: string;
  comment: string;
}

export type UploadId = string;

export interface WithUploadId {
  uploadid: UploadId;
}

export interface FileUpload extends WithUploadId {
  name: string;
}

export interface FileUploadStats extends FileUpload {
  progress: number;
}

export interface FileUploaded extends FileUpload {
  id: string;
}

export interface FileUploadError extends FileUpload {
  errorCodes: string[];
}

export interface ProgressActionPayload extends WithUploadId {
  progress: number;
}

export interface IChatState {
  chats: Record<DAChatId, ExtractionStatus>;
  activeChat: DAChatId | undefined;
  uploads: Record<UploadId, FileUploadStats>;
  uploaded: Record<UploadId, FileUpload>;
}

export enum ExtractionStatus {
  Done = 'done',
  Failed = 'failed',
  Running = 'running',
  Empty = 'empty',
}