import axios, { Axios, Canceler } from 'axios';

import { DAChatId } from '../store/reducers/chat.types';
import { MediaUploadResult } from '../store/reducers/media-upload.types';
import { FileTableExtractorResult } from '../store/reducers/te-upload.types';
import {
  FileTranslateResult,
  FileUploadResult,
} from '../store/reducers/upload.types';
import { Language } from './translation';

const progressionHandlerWrapper =
  (progress: (progress: number) => void) => (e: ProgressEvent) =>
    e.lengthComputable && progress(e.loaded / e.total);

export type ProgressionHandler = (progress: number) => void;

export function uploadFile<T>(
  url: string,
  payload: Record<string, string | Blob>,
  progress?: ProgressionHandler,
  _axios: Axios = axios
): {
  cancel: Canceler;
  promise: Promise<T>;
} {
  const form = new FormData();
  for (const name in payload) {
    const value = payload[name];
    form.append(name, value);
  }

  const { cancel, token } = axios.CancelToken.source();
  const isTestEnv = process.env.NODE_ENV === 'test';
  const onUploadProgress = !isTestEnv
    ? progress && progressionHandlerWrapper(progress)
    : undefined;

  const promise = _axios
    .post<T>(url, form, {
      cancelToken: token,
      onUploadProgress,
    })
    .then(({ data }) => data)
    .catch(error => {
      if (error.response) {                           // eslint-disable-line
        // API answered with an error
        if (error.response.data) {                    // eslint-disable-line
          throw new Error(error.response.data)        // eslint-disable-line
        }
        else {
          throw new Error(error.response.statusText)  // eslint-disable-line
        }
      }
      else {
        throw new Error('Unknown Error')
      }
    });
  return { cancel, promise };
}

export function uploadDocument(
  file: File,
  progress: ProgressionHandler,
  _axios: Axios
): {
  cancel: Canceler;
  promise: Promise<FileUploadResult>;
} {
  return uploadFile<FileUploadResult>(
    `/translation/document/info`,
    { file },
    progress,
    _axios
  );
}

export async function translateDocument(
  id: string,
  source: Language,
  target: Language,
  _axios: Axios
): Promise<FileTranslateResult> {
  return _axios
    .post<FileTranslateResult>('/translation/document', { id, source, target })
    .then(({ data }) => data)
    .catch(error => {
      if (error.response) {                           // eslint-disable-line
        // API answered with an error
        if (error.response.data) {                    // eslint-disable-line
          throw new Error(error.response.data)        // eslint-disable-line
        }
        else {
          throw new Error(error.response.statusText)  // eslint-disable-line
        }
      }
      else {
        throw new Error('Unknown Error')
      }
    });
}

export function transcribeMedia(
  file: File,
  language: Language,
  progress: ProgressionHandler,
  _axios: Axios
): {
  cancel: Canceler;
  promise: Promise<MediaUploadResult>;
} {
  return uploadFile<MediaUploadResult>(
    '/transcription',
    // `http://localhost:3001/upload`,
    { file, language },
    progress,
    _axios
  );
}

export function extractTable(
  file: File,
  progress: ProgressionHandler,
  _axios: Axios
): {
  cancel: Canceler;
  promise: Promise<FileTableExtractorResult>;
}{
  return uploadFile<FileTableExtractorResult>(
    '/table-extraction',
    { file },
    progress,
    _axios
  );
}

export function uploadDocToAnalyse(
  file: File,
  _axios: Axios,
  chatId?: DAChatId,
  progress?: ProgressionHandler,
): {
  cancel: Canceler;
  promise: Promise<FileUploadResult>;
}{
  return uploadFile<FileUploadResult>(
    chatId ? `/doc-analyser/${chatId}/document` : '/doc-analyser',
    { file },
    progress,
    _axios
  );
}