import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

interface FirstLoginState {
  isFisrtLOgin: boolean
}

const initialState = { isFisrtLOgin: true } as FirstLoginState

export const firstLoginSlice = createSlice({
  name: 'firstLogin',
  initialState,
  reducers: {
    setIsFirstLogin(state, action: PayloadAction<boolean>) {
      state.isFisrtLOgin = action.payload;
    },
  },
});

export const { setIsFirstLogin } = firstLoginSlice.actions;

export const firstLoginSelector = (state: RootState): FirstLoginState => state.firstLogin;

export const selectIsFirstLogin = createSelector(firstLoginSelector, (isFisrtLOgin) => isFisrtLOgin.isFisrtLOgin);

export default firstLoginSlice.reducer;
