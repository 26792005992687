import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { chatSlice } from './reducers/chat-slice';
import { filterSlice } from './reducers/filter-slice';
import { firstLoginSlice } from './reducers/firstLogin-slice';
import { languageSlice } from './reducers/language-slice';
import { mediaUploadSlice } from './reducers/media-upload-slice';
import { menuSlice } from './reducers/menu-slice';
import { notificationsSlice } from './reducers/notifications-slice';
import { onboardingSlice } from './reducers/onboarding-slice';
import { ssomappingSlice } from './reducers/sso-mapping-slice';
import { tableExtractorUploadSlice } from './reducers/te-upload-slice';
import { tmmanagerSlice } from './reducers/tm-manager-slice';
import { uploadSlice } from './reducers/upload-slice';
import { userInfoSlice } from './reducers/user-info-slice';

const reducers = combineReducers({
  [uploadSlice.name]: uploadSlice.reducer,
  [mediaUploadSlice.name]: mediaUploadSlice.reducer,
  [menuSlice.name]: menuSlice.reducer,
  [languageSlice.name]: languageSlice.reducer,
  [tableExtractorUploadSlice.name]: tableExtractorUploadSlice.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [onboardingSlice.name]: onboardingSlice.reducer,
  [firstLoginSlice.name]: firstLoginSlice.reducer,
  [chatSlice.name]: chatSlice.reducer,
  [tmmanagerSlice.name]: tmmanagerSlice.reducer,
  [filterSlice.name]: filterSlice.reducer,
  [userInfoSlice.name]: userInfoSlice.reducer,
  [ssomappingSlice.name]: ssomappingSlice.reducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['upload', 'mediaUpload', 'tmmanager', 'chat'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          uploadSlice.actions.addUpload.type,
          mediaUploadSlice.actions.setCancel.type,
          mediaUploadSlice.actions.addUploaded.type,
        ],
        ignoredPaths: ['upload.uploads', 'mediaUpload.transcriptionBoxes'],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;