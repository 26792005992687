import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios, { Axios } from 'axios';
import { Store } from 'react-notifications-component';

import {
  ProgressionHandler,
  translateDocument,
  uploadDocument,
} from '../../services/file-upload';
import { createid } from '../../services/id';
import { Language } from '../../services/translation';
import { RootState } from '../store';
import {
  FileType,
  FileUploaded,
  FileUploadError,
  FileUploadStats,
  IUploadState,
  ProgressActionPayload,
  TranslationBox,
  TranslationBoxId,
  UploadId,
  WithTranslationBoxId,
  WithUploadId,
} from './upload.types';

export enum FileUploadErrorCode {
  InvalidType = 'FileInvalidType',
  TooLarge = 'FileTooLarge',
  TooManyCharacter = 'TooManyCharacter',
  UnsupportedLanguage = 'UnsupportedLanguage',
  TooManyFiles = 'TooManyFiles',
  Unknown = 'Unknown',
}

export const translateFilesThunk = createAsyncThunk<
  boolean,
  { axios: Axios },
  {
    state: RootState;
    rejectValue: string;
  }
>('translateFiles', async ({ axios: _axios }, { dispatch, getState }) => {
  let onlyFails = true;
  const boxes = selectTranslationBoxesWithContent(getState());
  for (const { source, boxid, uploads } of boxes) {
    if (!source) continue;
    const files = Object.values(uploads);
    for (const { id, uploadid, langTargets, name, type, size } of files) {
      if (!langTargets.length) continue;
      for (const target of langTargets) {
        await translateDocument(id, source, target, _axios)
          .then(() => (onlyFails = false))
          .catch((error) => {
            dispatch(
              addUploadError({
                uploadid,
                errorCodes: [error.message], // eslint-disable-line
                name,
                size,
                type,
              })
            );
            Store.addNotification({
              title: `Your document ${name} has failed`,
              message: `${error}`, // eslint-disable-line
              type: 'danger',
              container: 'bottom-right',
              animationIn: ['animate__animated animate__fadeIn'],
              animationOut: ['animate__animated animate__fadeOut'],
              dismiss: {
                duration: 10000,
                onScreen: true,
                pauseOnHover: true,
                showIcon: true,
              },
            });
          });
      }
      dispatch(removeUploaded({ uploadid, boxid }));
    }
  }
  await dispatch(cleanBoxesThunk());
  return !onlyFails;
});

export const cleanBoxesThunk = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
    rejectValue: string;
  }
>('cleanBoxes', (_, { dispatch, getState }) => {
  const boxes = selectTranslationBoxes(getState());
  for (const { boxid, uploads } of boxes) {
    const files = Object.values(uploads);
    if (!files.length) dispatch(removeTranslationBox({ boxid }));
  }
});

export const uploadFileThunk = createAsyncThunk<
  void,
  { file: File; axios: Axios; lastTargetLanguages: Language[] },
  {
    rejectValue: string;
  }
>(
  'uploadFile',
  async ({ file, axios: _axios, lastTargetLanguages }, { dispatch }) => {
    const { name, size } = file;
    const type = name.split('.').pop()?.toLowerCase() as FileType;
    const uploadid = createid();

    const onProgress: ProgressionHandler = (progress) =>
      dispatch(setUploadProgress({ progress, uploadid }));

    const { cancel, promise } = uploadDocument(file, onProgress, _axios);

    dispatch(addUpload({ uploadid, cancel, name, size, progress: 0, type }));

    try {
      const result = await promise;
      if (result === undefined) throw new Error('Upload error. Try again');
      const type =
        result !== undefined
          ? (result.type.toLowerCase() as FileType)
          : FileType.TXT; // eslint-disable-line
      if (typeof result !== undefined && result.warning) {
        const errorCodes = [result.warning];
        dispatch(
          addUploadError({
            uploadid,
            errorCodes,
            name,
            size,
            type,
          })
        );
        dispatch(removeUpload({ uploadid }));
        return;
      }

      dispatch(
        addUploaded({
          name,
          uploadid,
          size,
          langDetected:
            typeof result !== undefined ? result.language : Language.EN, // eslint-disable-line
          langTargets: lastTargetLanguages,
          type,
          id: typeof result !== undefined ? result.id : '0', // eslint-disable-line
        })
      );
      dispatch(removeUpload({ uploadid }));
      void dispatch(recentlyUploadedThunk({ uploadid }));
    } catch (e) {
      if (axios.isCancel(e)) return;
      // TODO: get kind of possible error
      if (e instanceof Error) {
        dispatch(
          addUploadError({
            uploadid,
            errorCodes: [e.message],
            name,
            size,
            type,
          })
        );
        dispatch(removeUpload({ uploadid }));
        void dispatch(recentlyUploadedThunk({ uploadid }));
      } else {
        console.log('Unexpected error', e);
      }
    }
  }
);

export const recentlyUploadedThunk = createAsyncThunk<
  void,
  { uploadid: UploadId }
>('recentlyUploadedThunk', async ({ uploadid }, { dispatch }) => {
  dispatch(addRecentlyUploaded(uploadid));
  await new Promise((res) => setTimeout(res, 4_000));
  dispatch(removeRecentlyUploaded(uploadid));
});

export const initialState: IUploadState = {
  uploads: {},
  uploadsError: {},
  translationBoxes: {},
  isTranslating: false,
  recentlyUploaded: [],
};

export const uploadSlice = createSlice({
  name: 'upload',
  initialState,
  reducers: {
    addRecentlyUploaded(state, { payload }: PayloadAction<UploadId>) {
      state.recentlyUploaded.push(payload);
    },
    removeRecentlyUploaded(state, { payload }: PayloadAction<UploadId>) {
      const index = state.recentlyUploaded.indexOf(payload);
      if (index !== -1) state.recentlyUploaded.splice(index, 1);
    },

    addUploaded(
      state,
      { payload }: PayloadAction<FileUploaded & { boxid?: TranslationBoxId }>
    ) {
      const { boxid, ...file } = payload;
      const { langDetected, uploadid } = file;
      const translationBox = boxid
        ? state.translationBoxes[boxid]
        : Object.values(state.translationBoxes).find(
            (box) =>
              box.detected === langDetected && box.source === langDetected
          );
      if (translationBox) {
        translationBox.uploads[uploadid] = {
          ...file,
          langTargets: translationBox.applyToAll || file.langTargets || [],
        };
      } else {
        const id = createid();
        state.translationBoxes[id] = {
          boxid: id,
          source: langDetected,
          detected: langDetected,
          uploads: { [uploadid]: file },
          applyToAll: undefined,
        };
      }
    },
    removeUploaded(
      state,
      {
        payload: { uploadid, boxid },
      }: PayloadAction<WithUploadId & WithTranslationBoxId>
    ) {
      const box = state.translationBoxes[boxid];
      if (box) delete box.uploads[uploadid];
    },
    setTranslationBoxLang(
      state,
      {
        payload: { boxid, lang },
      }: PayloadAction<WithTranslationBoxId & { lang?: Language }>
    ) {
      const stateBox = state.translationBoxes[boxid];
      if (!stateBox) return;
      stateBox.source = lang;
    },
    setUploadedTargetLangs(
      state,
      {
        payload: { boxid, uploadid, langs },
      }: PayloadAction<
        WithUploadId &
          WithTranslationBoxId & {
            langs: Language[];
          }
      >
    ) {
      const stateBox = state.translationBoxes[boxid];
      if (!stateBox) return;
      const upload = stateBox.uploads[uploadid];
      if (!upload) return;
      upload.langTargets = langs;
    },
    addTranslationBox(
      state,
      { payload }: PayloadAction<TranslationBox | undefined>
    ) {
      const box = payload || {
        boxid: createid(),
        source: undefined,
        detected: undefined,
        uploads: {},
        applyToAll: undefined,
      };
      state.translationBoxes[box.boxid] = box;
    },
    moveUploaded(
      state,
      {
        payload: { fromBoxid, toBoxid, uploadid },
      }: PayloadAction<
        WithUploadId & {
          fromBoxid: TranslationBoxId;
          toBoxid: TranslationBoxId;
        }
      >
    ) {
      const boxFrom = state.translationBoxes[fromBoxid];
      const boxTo = state.translationBoxes[toBoxid];
      if (!boxFrom || !boxTo) return;
      if (!boxFrom.uploads[uploadid]) return;
      boxTo.uploads[uploadid] = boxFrom.uploads[uploadid];
      delete boxFrom.uploads[uploadid];
      if (boxTo.applyToAll)
        boxTo.uploads[uploadid].langTargets = boxTo.applyToAll;
    },
    removeTranslationBox(
      state,
      { payload: { boxid } }: PayloadAction<WithTranslationBoxId>
    ) {
      delete state.translationBoxes[boxid];
    },
    setApplyToAll(
      state,
      {
        payload: { boxid, langs },
      }: PayloadAction<WithTranslationBoxId & { langs?: Language[] }>
    ) {
      const stateBox = state.translationBoxes[boxid];
      if (!stateBox) return;
      stateBox.applyToAll = langs;
      if (langs) {
        for (const file of Object.values(stateBox.uploads)) {
          file.langTargets = langs;
        }
      }
    },

    addUpload(state, { payload }: PayloadAction<FileUploadStats>) {
      state.uploads[payload.uploadid] = payload;
    },
    removeUpload(
      state,
      { payload: { uploadid } }: PayloadAction<WithUploadId>
    ) {
      delete state.uploads[uploadid];
    },
    setUploadProgress(
      state,
      { payload: { uploadid, progress } }: PayloadAction<ProgressActionPayload>
    ) {
      const uploaded = state.uploads[uploadid];
      if (!uploaded) return;
      uploaded.progress = progress;
    },

    addUploadError(state, { payload }: PayloadAction<FileUploadError>) {
      state.uploadsError[payload.uploadid] = payload;
    },
    removeUploadError(
      state,
      { payload: { uploadid } }: PayloadAction<WithUploadId>
    ) {
      delete state.uploadsError[uploadid];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(translateFilesThunk.fulfilled, (state) => {
      state.isTranslating = false;
    });
    builder.addCase(translateFilesThunk.pending, (state) => {
      state.isTranslating = true;
    });
    builder.addCase(translateFilesThunk.rejected, (state) => {
      state.isTranslating = false;
    });
  },
});

export const {
  addRecentlyUploaded,
  removeRecentlyUploaded,
  setUploadProgress,
  addUpload,
  removeUpload,
  addUploaded,
  removeUploaded,
  setTranslationBoxLang,
  setUploadedTargetLangs,
  addTranslationBox,
  moveUploaded,
  removeTranslationBox,
  setApplyToAll,
  addUploadError,
  removeUploadError,
} = uploadSlice.actions;

export const uploadSelector = (state: RootState): IUploadState => state.upload;

export const selectUploads = createSelector(
  (state: RootState) => state.upload.uploads,
  (uploads) => Object.values(uploads)
);

export const selectTranslationBoxes = createSelector(
  (state: RootState) => state.upload.translationBoxes,
  (translationBoxes) => Object.values(translationBoxes)
);

export const selectTranslationBoxesWithContent = createSelector(
  selectTranslationBoxes,
  (boxes) => boxes.filter((box) => Object.values(box.uploads).length > 0)
);

export const selectUploadsError = createSelector(
  (state: RootState) => state.upload.uploadsError,
  (uploadsError) => Object.values(uploadsError)
);

export const selectHasUploads = createSelector(
  [selectUploads, selectTranslationBoxes, selectUploadsError],
  (uploads, boxes, errors) => uploads.length || boxes.length || errors.length
);

export const selectHasValidTranslations = createSelector(
  selectTranslationBoxes,
  (boxes) => {
    for (const box of boxes) {
      if (!box.source) continue;
      const uploads = Object.values(box.uploads);
      for (const file of uploads) {
        if (file.langTargets.length) return true;
      }
    }
    return false;
  }
);

export const selectIsMachineTranslationValid = createSelector(
  selectTranslationBoxesWithContent,
  (boxes) => {
    if (boxes.length === 0) return false;
    for (const box of boxes) {
      if (!box.source) return false;
      const uploads = Object.values(box.uploads);
      for (const file of uploads) {
        if (file.langTargets.length === 0) return false;
      }
    }
    return true;
  }
);

export const selectIsTranslating = createSelector(
  uploadSelector,
  (state) => state.isTranslating
);

export const selectRecentlyUploaded = createSelector(
  uploadSelector,
  (state) => state.recentlyUploaded
);
