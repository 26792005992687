import classNames from 'classnames';
import { createContext, FC, useCallback, useContext, useState } from 'react';
import { Toast, ToastContainer, ToastProps } from 'react-bootstrap';

export interface IToastOptions {
  title?: JSX.Element | string;
  body?: JSX.Element | string;
  delay?: number;
  props?: Omit<ToastProps, 'onClose' | 'delay'>;
  action?: (hideToast: () => void) => JSX.Element;
  onClose?: () => void;
}
export interface IToastContext {
  showToast: (this: void, options: IToastOptions) => void;
  hideToast: (this: void) => void;
}

export const ToastContext = createContext<IToastContext>(
  null as unknown as IToastContext
);

/**
 * this context provider provides an axios instance with an interceptor
 * setting the baseUrl and token for each request
 **/
export const ToastProvider: FC = ({ children }) => {
  const [toast, setToast] = useState<IToastOptions | null>(null);
  const [show, setShow] = useState(false);
  const [key, setKey] = useState(0);
  const hideToast = useCallback(() => setShow(false), []);

  const showToast = useCallback(
    (options: IToastOptions) => {
      setShow(true);
      setToast(options);
      setKey(key + 1);
    },
    [key]
  );

  const { props = {}, body, title, delay, action, onClose } = toast || {};
  const onToastClose = () => {
    onClose && onClose();
    setShow(false);
  };

  const context: IToastContext = {
    showToast,
    hideToast,
  };

  return (
    <ToastContext.Provider value={context}>
      {children}
      <ToastContainer position="bottom-center" className="position-fixed">
        {toast ? (
          <Toast
            {...props}
            delay={delay}
            onClose={onToastClose}
            show={show}
            autohide={!!delay}
            key={key}
          >
            {title ? <Toast.Header>{title}</Toast.Header> : void 0}
            <Toast.Body
              className={classNames({ 'text-light': props.bg === 'dark' })}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div>{body}</div>
                <div>{action && action(hideToast)}</div>
              </div>
            </Toast.Body>
          </Toast>
        ) : (
          void 0
        )}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export const useToast = (): IToastContext => useContext(ToastContext);
