import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Language } from '../../services/translation';
import { CalendarDate, DocType, FileName, IFilterState } from './filter.types';

export const initialState: IFilterState = {
  fileName: '',
  docType: [],
  fromDate: 0,
  toDate: 0,
  minDate: 0,
  maxDate: 0,
  variant: '',
  language: undefined,
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFileName: (
      state: IFilterState,
      { payload }: PayloadAction<FileName>
    ) => {
      state.fileName = payload;
    },
    setDocType: (state, { payload }: PayloadAction<DocType>) => {
      state.docType = payload;
    },
    setFromDate: (state, { payload }: PayloadAction<CalendarDate>) => {
      state.fromDate = payload;
    },
    setToDate: (state, { payload }: PayloadAction<CalendarDate>) => {
      state.toDate = payload;
    },
    setMinDate: (state, { payload }: PayloadAction<CalendarDate>) => {
      state.minDate = payload;
    },
    setMaxDate: (state, { payload }: PayloadAction<CalendarDate>) => {
      state.maxDate = payload;
    },
    setVariant: (state, { payload }: PayloadAction<string>) => {
      state.variant = payload;
    },
    setLanguage: (state, { payload }: PayloadAction<Language | undefined>) => {
      state.language = payload;
    },
  },
});

export const {
  setFileName,
  setDocType,
  setFromDate,
  setToDate,
  setMinDate,
  setMaxDate,
  setVariant,
  setLanguage,
} = filterSlice.actions;

export const filterReducer = filterSlice.reducer;