import React, { FC } from 'react';

import { ProtectedRoute } from '../components/ProtectedRoute';

export const suspense = (
  Comp: FC,
  { protect = true, fallback = <>Loading...</> } = {}
): JSX.Element => (
  <React.Suspense fallback={fallback}>
    {protect ? (
      <ProtectedRoute>
        <Comp />
      </ProtectedRoute>
    ) : (
      <Comp />
    )}
  </React.Suspense>
);
