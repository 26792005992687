import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios, { Axios, Canceler } from 'axios';
import { Store } from 'react-notifications-component';

import {
  ProgressionHandler,
  transcribeMedia,
} from '../../services/file-upload';
import { createid } from '../../services/id';
import { Language } from '../../services/translation';
import { RootState } from '../store';
import {
  IMediaUploadState,
  MediaUploadError,
  MediaUploadStats,
  ProgressActionPayload,
  TranscriptionBox,
  TranscriptionBoxId,
  WithTranscriptionBoxId,
  WithUploadId,
} from './media-upload.types';
import { FileType, UploadId, WithTranslationBoxId } from './upload.types';

export const uploadMediaThunk = createAsyncThunk<
  void,
  { file: File; boxid?: string },
  {
    state: RootState;
    rejectValue: string;
  }
>('uploadMedia', async ({ file, boxid }, { dispatch }) => {
  const { name, size } = file;
  const type = name.split('.').pop()?.toLowerCase() as FileType;
  const uploadid = createid();

  // dispatch(addUpload({ uploadid, name, size, progress: 0, type, file }));
  // Adding a slight delay in the processing of the file to avoid error: `Uncaught Invariant Violation: Cannot call hover while not dragging.,`
  await new Promise((res) => setTimeout(res, 0));
  dispatch(
    addUploaded({
      name,
      uploadid,
      size,
      type,
      language: Language.AUTO,
      id: uploadid,
      boxid,
      file,
      progress: 0,
    })
  );
  void dispatch(recentlyUploadedThunk({ uploadid }));
});

export const recentlyUploadedThunk = createAsyncThunk<
  void,
  { uploadid: UploadId }
>('recentlyUploadedThunk', async ({ uploadid }, { dispatch }) => {
  dispatch(addRecentlyUploaded(uploadid));
  await new Promise((res) => setTimeout(res, 4_000));
  dispatch(removeRecentlyUploaded(uploadid));
});

export const initialState: IMediaUploadState = {
  uploadsError: {},
  transcriptionBoxes: {},
  recentlyUploaded: [],
  isTranscribing: false,
};

export const transcribeFilesThunk = createAsyncThunk<
  boolean,
  { axios: Axios },
  {
    state: RootState;
    rejectValue: string;
  }
>('transcribeFiles', async ({ axios: _axios }, { dispatch, getState }) => {
  const state = getState();
  const boxes = selectTranscriptionBoxesWithContent(state);
  const onlyFails = new Map();
  for (const { language, boxid, uploads } of boxes) {
    if (!language) continue;
    const files = Object.values(uploads);
    await Promise.all(
      files.map((media) =>
        dispatch(transcribeSingleFileThunk({ axios: _axios, media, language, boxid }))
      )
    ).then((response) => {
      onlyFails.set(boxid, response.every(i => i.payload !== undefined)); // if payload !== undefined then fail
    });
  }
  await dispatch(cleanBoxesThunk());
  return !Array.from(onlyFails.values()).every(i => i === true);
});

export const transcribeSingleFileThunk = createAsyncThunk<
  void,
  { axios: Axios; media: MediaUploadStats; language: Language; boxid: string },
  {
    state: RootState;
    rejectValue: string;
  }
>(
  'transcribeSingleFileThunk',
  async ({ axios: _axios, media, language, boxid }, { dispatch, rejectWithValue }) => {
    const { uploadid, file } = media;
    const onProgress: ProgressionHandler = (progress) =>
      dispatch(setUploadProgress({ progress, uploadid, boxid }));

    const { cancel, promise } = transcribeMedia(
      file,
      language,
      onProgress,
      _axios
    );
    dispatch(setCancel({ uploadid, cancel, boxid }));
    try {
      await promise;
      dispatch(removeUploaded({ uploadid, boxid }));
    } catch (e) {
      if (axios.isCancel(e)) return;
      // TODO: get kind of possible error
      const { name, size } = file;
      const type = name.split('.').pop()?.toLowerCase() as FileType;
      if (e instanceof Error) {
        dispatch(
          addUploadError({
            uploadid,
            errorCodes: [e.message],
            name,
            size,
            type,
          })
        );
        dispatch(removeUploaded({ uploadid, boxid }));
        // dispatch(removeUploadError({ uploadid })); // remove le file avec l'erreur de la page d'upload
        // eslint-disable-next-line
        Store.addNotification({
          title: `Your document ${name} has failed`,
          message: `${e.message}`,
          type: 'danger',
          container: 'bottom-right',
          animationIn: ['animate__animated animate__fadeIn'],
          animationOut: ['animate__animated animate__fadeOut'],
          dismiss: {
            duration: 10000,
            onScreen: true,
            pauseOnHover: true,
            showIcon: true,
          },
        });
        return rejectWithValue("error")
      } else {
        console.log('Unexpected error', e);
      }
    }
    dispatch(removeUploaded({ uploadid, boxid }));
  }
);

export const cleanBoxesThunk = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
    rejectValue: string;
  }
>('cleanBoxes', (_, { dispatch, getState }) => {
  const boxes = selectTranscriptionBoxes(getState());
  for (const { boxid, uploads } of boxes) {
    const files = Object.values(uploads);
    if (!files.length) dispatch(removeTranscriptionBox({ boxid }));
  }
});

export const mediaUploadSlice = createSlice({
  name: 'mediaUpload',
  initialState,
  reducers: {
    addUploadError(state, { payload }: PayloadAction<MediaUploadError>) {
      state.uploadsError[payload.uploadid] = payload;
    },
    removeUploadError(
      state,
      { payload: { uploadid } }: PayloadAction<WithUploadId>
    ) {
      delete state.uploadsError[uploadid];
    },
    setCancel(
      state,
      {
        payload: { uploadid, cancel, boxid },
      }: PayloadAction<
        { cancel: Canceler } & WithUploadId & WithTranslationBoxId
      >
    ) {
      const uploaded = state.transcriptionBoxes[boxid]?.uploads[uploadid];
      if (!uploaded) return;
      uploaded.cancel = cancel;
    },
    setUploadProgress(
      state,
      {
        payload: { uploadid, progress, boxid },
      }: PayloadAction<ProgressActionPayload & WithTranslationBoxId>
    ) {
      const uploaded = state.transcriptionBoxes[boxid]?.uploads[uploadid];
      if (!uploaded) return;
      uploaded.progress = progress;
    },
    addUploaded(
      state,
      {
        payload,
      }: PayloadAction<
        MediaUploadStats & { boxid?: TranscriptionBoxId } & {
          language?: Language | undefined;
        }
      >
    ) {
      const { boxid, language, ...file } = payload;
      const { uploadid } = file;
      const transcriptionBox = boxid
        ? state.transcriptionBoxes[boxid]
        : Object.values(state.transcriptionBoxes).find(
            (box) => box.language === language
          );

      if (transcriptionBox) {
        transcriptionBox.uploads[uploadid] = {
          ...file,
        };
      } else {
        const id = createid();
        state.transcriptionBoxes[id] = {
          boxid: id,
          language: language,
          uploads: { [uploadid]: file },
        };
      }
    },
    moveUploaded(
      state,
      {
        payload: { fromBoxid, toBoxid, uploadid },
      }: PayloadAction<
        WithUploadId & {
          fromBoxid: TranscriptionBoxId;
          toBoxid: TranscriptionBoxId;
        }
      >
    ) {
      const boxFrom = state.transcriptionBoxes[fromBoxid];
      const boxTo = state.transcriptionBoxes[toBoxid];
      if (!boxFrom || !boxTo) return;
      if (!boxFrom.uploads[uploadid]) return;
      boxTo.uploads[uploadid] = boxFrom.uploads[uploadid];
      delete boxFrom.uploads[uploadid];
    },
    removeUploaded(
      state,
      {
        payload: { uploadid, boxid },
      }: PayloadAction<WithUploadId & WithTranscriptionBoxId>
    ) {
      const box = state.transcriptionBoxes[boxid];
      if (box) delete box.uploads[uploadid];
    },
    addRecentlyUploaded(state, { payload }: PayloadAction<UploadId>) {
      state.recentlyUploaded.push(payload);
    },
    removeRecentlyUploaded(state, { payload }: PayloadAction<UploadId>) {
      const index = state.recentlyUploaded.indexOf(payload);
      if (index !== -1) state.recentlyUploaded.splice(index, 1);
    },
    addTranscriptionBox(
      state,
      { payload }: PayloadAction<TranscriptionBox | undefined>
    ) {
      const box = payload || {
        boxid: createid(),
        language: Language.AUTO,
        uploads: {},
      };
      state.transcriptionBoxes[box.boxid] = box;
    },
    setTranscriptionBoxLang(
      state,
      {
        payload: { boxid, lang },
      }: PayloadAction<WithTranscriptionBoxId & { lang?: Language }>
    ) {
      const stateBox = state.transcriptionBoxes[boxid];
      if (!stateBox) return;
      stateBox.language = lang;
    },
    removeTranscriptionBox(
      state,
      { payload: { boxid } }: PayloadAction<WithTranscriptionBoxId>
    ) {
      delete state.transcriptionBoxes[boxid];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(transcribeFilesThunk.fulfilled, (state) => {
      state.isTranscribing = false;
    });
    builder.addCase(transcribeFilesThunk.pending, (state) => {
      state.isTranscribing = true;
    });
    builder.addCase(transcribeFilesThunk.rejected, (state) => {
      state.isTranscribing = false;
    });
  },
});

export const {
  addUploadError,
  removeUploadError,
  setCancel,
  setUploadProgress,
  addUploaded,
  moveUploaded,
  removeUploaded,
  addRecentlyUploaded,
  removeRecentlyUploaded,
  setTranscriptionBoxLang,
  addTranscriptionBox,
  removeTranscriptionBox,
} = mediaUploadSlice.actions;

export const mediaUploadSelector = (state: RootState): IMediaUploadState =>
  state.mediaUpload;

export const selectUploadsError = createSelector(
  mediaUploadSelector,
  (mediaUpload) => Object.values(mediaUpload.uploadsError)
);

export const selectTranscriptionBoxes = createSelector(
  mediaUploadSelector,
  (mediaUpload) => Object.values(mediaUpload.transcriptionBoxes)
);

export const selectHasUploads = createSelector(
  [selectTranscriptionBoxes, selectUploadsError],
  (boxes, errors) => boxes.length || errors.length
);

export const selectTranscriptionBoxesWithContent = createSelector(
  selectTranscriptionBoxes,
  (boxes) => boxes.filter((box) => Object.values(box.uploads).length > 0)
);

export const selectHasValidTranscriptions = createSelector(
  selectTranscriptionBoxesWithContent,
  (boxes) => {
    return boxes.some((box) => !!box.language);
  }
);

export const selectIsTranscribing = createSelector(
  mediaUploadSelector,
  (state) => state.isTranscribing
);

export const selectRecentlyUploaded = createSelector(
  mediaUploadSelector,
  (state) => state.recentlyUploaded
);