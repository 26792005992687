import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IMenuState } from './menu.type';

export const initialState: IMenuState = {
  open: true,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setOpen(state, { payload }: PayloadAction<boolean>) {
      state.open = payload;
    },
  },
});

export const { setOpen } = menuSlice.actions;

export const menuSelector = (state: RootState): IMenuState => state.menu;

export const selectOpen = createSelector(menuSelector, (menu) => menu.open);
