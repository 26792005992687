import Keycloak from 'keycloak-js';
import escapeRegExp from 'lodash/escapeRegExp';

const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
};
const IdpHintRegexp = new RegExp(
  `^\\?${escapeRegExp(process.env.REACT_APP_KEYCLOAK_IDP_HINT)}=([^&]+)$`
);
const keycloak = patchKeycloakLogin(Keycloak(keycloakConfig));

export default keycloak;

function getIdpHint(): string | null {
  // search for a single url query param named kc_idp_hint
  const match = IdpHintRegexp.exec(window.location.search);
  if (!match) return null;
  // removing the query param named kc_idp_hint from url
  window.history.replaceState(
    null,
    '',
    window.location.href.slice(0, window.location.href.indexOf('?'))
  );
  // returning value of query param named kc_idp_hint
  return match[1];
}

function patchKeycloakLogin(
  keycloak: Keycloak.KeycloakInstance
): Keycloak.KeycloakInstance {
  const idpHint = getIdpHint();
  if (!idpHint) return keycloak;
  // save original login function reference
  const originalLogin = keycloak.login.bind(keycloak);
  // replace keycloak login function
  keycloak.login = (options = {}) => {
    // add idpHint if not already present
    options.idpHint = options.idpHint ?? idpHint;
    // call original login function
    return originalLogin(options);
  };
  return keycloak;
}
