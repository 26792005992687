import axios, { Axios } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';

export enum Language {
  AR = 'ar', // Arabic
  BG = 'bg', // Bulgarian
  CA = 'ca', // Catalan
  CMN = 'cmn', // Mandarin
  CS = 'cs', // Czech
  DA = 'da', // Danish
  DE = 'de', // German
  EN = 'en', // English
  EL = 'el', // Greek
  ES = 'es', // Spanish
  FI = 'fi', // Finnish
  FR = 'fr', // French
  HI = 'hi', // Hindi
  HR = 'hr', // Croatian
  HU = 'hu', // Hungarian
  ID = 'id', // Indonesian
  IT = 'it', // Italian
  JA = 'ja', // Japanese
  KO = 'ko', // Korean
  LT = 'lt', // Lithuanian
  LV = 'lv', // Latvian
  MS = 'ms', // Malay
  NL = 'nl', // Dutch
  NO = 'no', // Norwegian
  PL = 'pl', // Polish
  PT = 'pt', // Portuguese
  RO = 'ro', // Romanian
  RU = 'ru', // Russian
  SK = 'sk', // Slovak
  SL = 'sl', // Slovenian
  SV = 'sv', // Swedish
  TR = 'tr', // Turkish
  YUE = 'yue', // Cantonese
  ZH = 'zh', // Chinese
  ZHS = 'zh-hans', // Simplified Chinese
  ZHT = 'zh-hant', // Traditional Chinese
  AUTO = 'auto'
}

export interface Engine {
  name: string;
  source: Language;
  target: Language;
  domain: string;
  version: string;
  tags: number;
}

export const getEngines = async (
  _axios: Axios = axios
): Promise<Map<Language, Set<Language>>> => {
  const { data: engines } = await _axios.get<Engine[]>(`/translation/engines`);

  return engines.reduce((acc, engine) => {
    const { source, target } = engine;
    if (!acc.has(source)) acc.set(source, new Set());
    const targets = acc.get(source);
    targets!.add(target); // eslint-disable-line @typescript-eslint/no-non-null-assertion
    return acc;
  }, new Map<Language, Set<Language>>());
};

export const useGetEngines = (
  _axios: Axios = axios
): UseQueryResult<Map<Language, Set<Language>>, unknown> =>
  useQuery('engines', () => getEngines(_axios), {
    staleTime: Infinity,
  });

export const getLanguages = async (
  _axios: Axios = axios
): Promise<Map<Language, Set<Language>>> => {
  const { data: languages } = await _axios.get<{ [key: string]: string[] }>(
    `/translation/languages`
  );

  const languageMap = new Map<Language, Set<Language>>();

  Object.entries(languages).forEach(([source, targets]) => {
    const sourceLanguage = source as Language;
    if (!languageMap.has(sourceLanguage)) {
      languageMap.set(sourceLanguage, new Set());
    }
    const targetSet = languageMap.get(sourceLanguage);
    targets.forEach((target) => targetSet!.add(target as Language));  // eslint-disable-line @typescript-eslint/no-non-null-assertion
  });

  return languageMap;
};

export const useGetLanguages = (
  _axios: Axios = axios
): UseQueryResult<Map<Language, Set<Language>>, unknown> =>
  useQuery('languages', () => getLanguages(_axios), {
    staleTime: Infinity,
  });
