import { ExtractionStatusIcon } from "../pages/Application/DocumentAnalyser/components/ExtractionStatusIcon/ExtractionStatusIcon";
import { DAFile, ExtractionStatus } from "../store/reducers/chat.types";

export const getDAExtractStatus = (files: DAFile[]) => {
    // rules :
    // if no files -> EMPTY
    // if ONE failed -> FAILED
    // if no failed but not all done -> RUNNING
    if(files === undefined || files.length === 0) return ExtractionStatus.Empty
    else {
        if(files.filter(f => f.status === ExtractionStatus.Failed).length !== 0) return ExtractionStatus.Failed
        else {
            if(files.filter(f => f.status !== ExtractionStatus.Done).length === 0) return ExtractionStatus.Done
            else return ExtractionStatus.Running
        }
    }
}

export const displayExtractionStatus = (status: ExtractionStatus) => {
    if(status !== ExtractionStatus.Done) return <ExtractionStatusIcon status={status} />;
};