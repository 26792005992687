import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createid } from '../../services/id';
import { RootState } from '../store';
import { ISsoMappingState, Mapping  } from './sso-mapping.types';

export const initialState: ISsoMappingState = {
  mappings: {},
  changes: false,
};

export const ssomappingSlice = createSlice({
  name: 'ssomapping',
  initialState,
  reducers: {
    setSSOMappings(state, { payload }: PayloadAction<Record<string,Mapping>>) {
      state.mappings = payload; //eslint-disable-line
    },
    addSSOMapping(state, { payload }: PayloadAction<Mapping | undefined>) {
      const mapping = payload || {
        mappingId: createid(),
        email: '',
        group: '',
        mailError: '',
        groupError: '',
      };
      state.mappings[mapping.mappingId] = mapping; //eslint-disable-line
    },
    updateSSOMappingGroup(state, { payload }: PayloadAction<{mappingId: string} & {group: string}>) {
      state.mappings[payload.mappingId].group = payload.group;
    },
    updateSSOMappingEmail(state, { payload }: PayloadAction<{mappingId: string} & {email: string}>) {
      state.mappings[payload.mappingId].email = payload.email;
    },
    deleteSSOMapping(state, { payload }: PayloadAction<string>) {
      delete state.mappings[payload];
    },
    addSSOMappingMailError(state, { payload: {mappingId, error} }: PayloadAction<{mappingId: string} & {error: string}>) {
      state.mappings[mappingId].mailError = error;
    },
    addSSOMappingGroupError(state, { payload: {mappingId, error} }: PayloadAction<{mappingId: string} & {error: string}>) {
      state.mappings[mappingId].groupError = error;
    },
    setSSOMappingChanges(state, { payload }: PayloadAction<boolean>) {
      state.changes = payload;
    },
    deleteAllSSOMappingErrors(state) {
      const updatedMappings = Object.fromEntries(
        Object.entries(state.mappings).map(([key, mapping]) => [
          key,
          {
            ...mapping,
            mailError: '',
            groupError: '',
          },
        ])
      );

      return {
        ...state,
        mappings: updatedMappings,
      };
    }
  },
});

export const {
  setSSOMappings,
  addSSOMapping,
  updateSSOMappingGroup,
  updateSSOMappingEmail,
  deleteSSOMapping,
  addSSOMappingMailError,
  addSSOMappingGroupError,
  setSSOMappingChanges,
  deleteAllSSOMappingErrors
} = ssomappingSlice.actions;

export const ssomappingSelector = (state: RootState): ISsoMappingState => state.ssomapping;

export const selectSSOMappings = createSelector(ssomappingSelector, (state) => state.mappings);
export const selectSSOMappingsChanges = createSelector(ssomappingSelector, (state) => state.changes);