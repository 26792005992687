import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { IOnboardingState } from './onboarding.type';

export const initialState: IOnboardingState = {
    acceptedOnboarding: false,
    hasSeenNavigationOnboarding: false,
    hasSeenDocTranslationOnboarding: false,
    continueToDocTranslationOnboarding: false,
    hasSeenSpeechToTextOnboarding: false,
    hasSeenTextTranslationOnboarding: false,
    hasSeenTableExtractorOnboarding: false,
    hasSeenDocAnalyserOnboarding: false,
    // doc translation
    continueToTableLanguageOnboarding: false,
    // speech to text
    continueToSpeechTableLanguageOnboarding: false,
    // table extractor
    continueToTableExtractorFileBoxOnboarding: false,
    // doc analyser
    continueToDocAnalyserChat: false,
    hasSeenTMManagerOnboarding: false,
    hasSeenNewUnitOnboarding: false,
    hasSeenVariantsOnboarding: false,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setAcceptedOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.acceptedOnboarding = payload;
    },
    setHasSeenDocTranslationOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.hasSeenDocTranslationOnboarding = payload;
    },
    setHasSeenNavigationOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.hasSeenNavigationOnboarding = payload;
    },
    setContinueToDocTranslationOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.continueToDocTranslationOnboarding = payload;
    },
    setHasSeenSpeechToTextOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.hasSeenSpeechToTextOnboarding = payload;
    },
    setHasSeenTextTranslationOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.hasSeenTextTranslationOnboarding = payload;
    },
    setContinueToTableLanguageOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.continueToTableLanguageOnboarding = payload;
    },
    setContinueToSpeechTableLanguageOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.continueToSpeechTableLanguageOnboarding = payload;
    },
    setContinueToTableExtractorFileBoxOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.continueToTableExtractorFileBoxOnboarding = payload;
    },
    setHasSeenTableExtractorOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.hasSeenTableExtractorOnboarding = payload;
    },
    setHasSeenDocAnalyserOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.hasSeenDocAnalyserOnboarding = payload;
    },
    setContinueToDocAnalyserChat(state, { payload }: PayloadAction<boolean>) {
      state.continueToDocAnalyserChat = payload;
    },
    setHasSeenTMManagerOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.hasSeenTMManagerOnboarding = payload;
    },
    setHasSeenNewUnitOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.hasSeenNewUnitOnboarding = payload;
    },
    setHasSeenVariantsOnboarding(state, { payload }: PayloadAction<boolean>) {
      state.hasSeenVariantsOnboarding = payload;
    },
  },
});

export const { 
    setAcceptedOnboarding,
    setHasSeenNavigationOnboarding,
    setHasSeenDocTranslationOnboarding,
    setContinueToDocTranslationOnboarding,
    setHasSeenSpeechToTextOnboarding,
    setHasSeenTextTranslationOnboarding,
    setContinueToTableLanguageOnboarding,
    setContinueToSpeechTableLanguageOnboarding,
    setContinueToTableExtractorFileBoxOnboarding,
    setHasSeenTableExtractorOnboarding,
    setHasSeenDocAnalyserOnboarding,
    setContinueToDocAnalyserChat,
    setHasSeenTMManagerOnboarding,
    setHasSeenNewUnitOnboarding,
    setHasSeenVariantsOnboarding,
} = onboardingSlice.actions;

export const onboardingSelector = (state: RootState): IOnboardingState => state.onboarding;

export const selectAcceptedOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.acceptedOnboarding);
export const selectHasSeenDocTranslationOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.hasSeenDocTranslationOnboarding);
export const selectHasSeenNavigationOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.hasSeenNavigationOnboarding);
export const selectContinueToDocTranslationOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.continueToDocTranslationOnboarding);
export const selectHasSeenSpeechToTextOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.hasSeenSpeechToTextOnboarding);
export const selectHasSeenTextTranslationOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.hasSeenTextTranslationOnboarding);
export const selectContinueToTableLanguageOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.continueToTableLanguageOnboarding);
export const selectContinueToSpeechTableLanguageOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.continueToSpeechTableLanguageOnboarding);
export const selectContinueToTableExtractorFileBoxOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.continueToTableExtractorFileBoxOnboarding);
export const selectHasSeenTableExtractorOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.hasSeenTableExtractorOnboarding);
export const selectHasSeenDocAnalyserOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.hasSeenDocAnalyserOnboarding); //eslint-disable-line
export const selectContinueToDocAnalyserChat = createSelector(onboardingSelector, (onboarding) => onboarding.continueToDocAnalyserChat);// eslint-disable-line
export const selectHasSeenTMManagerOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.hasSeenTMManagerOnboarding);// eslint-disable-line
export const selectHasSeenNewUnitOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.hasSeenNewUnitOnboarding);// eslint-disable-line
export const selectHasSeenVariantsOnboarding = createSelector(onboardingSelector, (onboarding) => onboarding.hasSeenVariantsOnboarding);// eslint-disable-line