import { FC } from 'react';

//import { selectIsLoggedIn } from '../store/reducers/login-slice';

export interface ProtectedRouteNavigationState {
  from: string;
}

export const ProtectedRoute: FC = ({ children }) => {
  //const isLoggedIn = useSelector(selectIsLoggedIn);
  //const location = useLocation();

  /*if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }*/

  return <>{children}</>;
};
