import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createid } from '../../services/id';
import { Language } from '../../services/translation';
import { RootState } from '../store';
import { ITMManagerState, NewUnit  } from './tm-manager.types';
import { UploadId } from './upload.types';

export const initialState: ITMManagerState = {
  newUnits: {},
  lastUsedLanguages: [] as Language[],
};

export const tmmanagerSlice = createSlice({
  name: 'tmmanager',
  initialState,
  reducers: {
    addNewUnit(state, { payload }: PayloadAction<NewUnit | undefined>) {
      const unit = payload || {
        unitId: createid(),
        variants: {},
        error: '',
      };
      state.newUnits[unit.unitId] = unit; //eslint-disable-line
      const variant = {
        id: createid(),
        text: '',
        language: state.lastUsedLanguages.length > 0 ? state.lastUsedLanguages.shift() : Language.EN,
        edited: false,
      };
      state.newUnits[unit.unitId].variants[variant.id]=variant; //eslint-disable-line
    },
    deleteNewUnit(state, { payload }: PayloadAction<UploadId>) {
      delete state.newUnits[payload];
    },
    addNewVariant(state, { payload }: PayloadAction<UploadId>) {
      const variant = {
        id: createid(),
        text: '',
        language: state.lastUsedLanguages.length > 0 ? state.lastUsedLanguages.shift() : Language.EN,
        edited: false,
      };
      state.newUnits[payload].variants[variant.id]=variant;
    },
    updateVariant(state, { payload: {unitId, variantid, textValue} }: PayloadAction<{unitId: UploadId} & {variantid: UploadId} & {textValue: string}>) {
      state.newUnits[unitId].variants[variantid].edited = true; // eslint-disable-line
      state.newUnits[unitId].variants[variantid].text = textValue; // eslint-disable-line
    },
    updateVariantLanguage(state, { payload: {unitId, variantid, language} }: PayloadAction<{unitId: UploadId} & {variantid: UploadId} & {language: Language}>) {
      state.newUnits[unitId].variants[variantid].language = language; // eslint-disable-line
    },
    addNewUnitError(state, { payload: {unitId, error} }: PayloadAction<{unitId: UploadId} & {error: string}>) {
      state.newUnits[unitId].error = error;
    },
    addUsedLanguage(state, { payload }: PayloadAction<Language | undefined>) {
      if(payload !== undefined) state.lastUsedLanguages.push(payload);
    },
    addUsedLanguageByUnshift(state, { payload }: PayloadAction<Language | undefined>) {
      if(payload !== undefined) state.lastUsedLanguages.unshift(payload);
    },
  },
});

export const { 
  addNewUnit, 
  deleteNewUnit, 
  addNewVariant, 
  updateVariant,
  updateVariantLanguage,
  addNewUnitError,
  addUsedLanguage,
  addUsedLanguageByUnshift
} = tmmanagerSlice.actions;

export const tmmanagerSelector = (state: RootState): ITMManagerState => state.tmmanager;

export const selectNewUnits = createSelector(tmmanagerSelector, (state) => state.newUnits);
export const selectLastUsedLanguages = createSelector(tmmanagerSelector, (state) => state.lastUsedLanguages);