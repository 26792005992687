import { Popover } from 'react-bootstrap';

import styles from './OverlayPopover.module.scss';

export const getPopover = (label: string) => {
  return (
    <Popover className={styles.popover}>
      <Popover.Body className={styles.popoverBody}>{label}</Popover.Body>
    </Popover>
  );
};