import { faCircleCheck, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from 'i18next';
import { FC } from "react";
import { OverlayTrigger, Spinner } from "react-bootstrap";

import { getPopover } from "../../../../../components/OverlayPopover/OverlayPopover";
import { ExtractionStatus } from '../../../../../store/reducers/chat.types'

interface ExtractionStatusIconProps {
  status?: ExtractionStatus;
}

export const ExtractionStatusIcon: FC<ExtractionStatusIconProps> = ({status}) => {
  switch(status) {
    case ExtractionStatus.Running:
        return(
            <Spinner animation="border" size="sm" />
        )
    case ExtractionStatus.Done:
        return(
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 0, hide: 0 }}
                overlay={getPopover(t('docAnalyser.dataExtraction.success'))}
            >
                <div>
                    <FontAwesomeIcon
                        icon={faCircleCheck}
                        color="green"
                        size="lg"
                    />
                </div>
            </OverlayTrigger>
        )
    case ExtractionStatus.Failed:
        return(
            <OverlayTrigger
                placement="bottom"
                delay={{ show: 0, hide: 0 }}
                overlay={getPopover(t('docAnalyser.dataExtraction.fail'))}
            >
                <div>
                <FontAwesomeIcon
                    icon={faCircleExclamation}
                    color="red"
                    size="lg"
                />
                </div>
            </OverlayTrigger>
        )
    default:
        return(
            <></>
        )
  }
}