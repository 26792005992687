import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { Axios } from 'axios';
import { Store } from 'react-notifications-component';

import { getDAExtractStatus } from '../../services/da-extract-status';
import {
  ProgressionHandler,
  uploadDocToAnalyse,
} from '../../services/file-upload';
import { createid } from '../../services/id';
import { addNotification } from '../../services/notification';
import { RootState } from '../store';
import {
  DAChat,
  DAChatId,
  ExtractionStatus,
  FileUploadStats,
  IChatState,
  ProgressActionPayload,
  WithUploadId,
} from './chat.types';

export const uploadDAFileThunk = createAsyncThunk<
  void,
  { file: File; axios: Axios; chatId: DAChatId; onUpdateAfterUpload: () => void },
  {
    rejectValue: string;
  }
>(
  'uploadDAFile',
  async ({ file, axios: _axios, chatId, onUpdateAfterUpload }, { dispatch }) => {
    const { name } = file;
    const uploadid = createid();

    const onProgress: ProgressionHandler = (progress) =>
      dispatch(setUploadProgress({ progress, uploadid }));

    const { promise } = uploadDocToAnalyse(
      file,
      _axios,
      chatId,
      onProgress
    );

    dispatch(addDAUpload({ uploadid, name, progress: 0 }));

    try {
      await promise;
      dispatch(removeUpload({ uploadid }));
      onUpdateAfterUpload();
    } catch (e) {
      if (e instanceof Error) {
        addNotification(
          Store,
          `An error occured while analysing ${name}`,
          e.message,
          'danger'
        );
        dispatch(removeUpload({ uploadid }));
      } else {
        console.log('Unexpected error', e);
      }
    }
  }
);

export const initialState: IChatState = {
  chats: {},
  activeChat: undefined,
  uploads: {},
  uploaded: {},
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setUploadProgress(
      state,
      { payload: { uploadid, progress } }: PayloadAction<ProgressActionPayload>
    ) {
      const uploaded = state.uploads[uploadid];
      if (!uploaded) return;
      uploaded.progress = progress;
    },
    setChats(state, { payload }: PayloadAction<DAChat[]>) {
      if (payload) {
        const chats: Record<DAChatId, ExtractionStatus> = {};
        payload
          .sort(function (a, b) {
            return Date.parse(b.date) - Date.parse(a.date);
          })
          .map((n) => {
            chats[n.id] = getDAExtractStatus(n.files);
          });
        if (payload.length === Object.keys(state.chats).length + 1) {
          state.chats = chats;
          state.activeChat = Object.keys(chats)[0];
        }
        if (payload.length === Object.keys(state.chats).length - 1) {
          state.chats = chats;
          state.activeChat = undefined;
        }
        state.chats = chats;
        if (state.activeChat === undefined)
          state.activeChat = Object.keys(chats)[0];
      } else {
        state.chats = {};
        state.activeChat = undefined;
      }
    },
    setActiveChat(state, { payload }: PayloadAction<DAChatId | undefined>) {
      state.activeChat = payload;
    },
    setChatStatus(state, { payload: {chatId, status} }: PayloadAction<{chatId: DAChatId} & {status: ExtractionStatus}>) {
      state.chats[chatId] = status;
    },
    addChat(state, { payload }: PayloadAction<DAChatId>) {
      // all of this to keep the order "last added on top of the list"
      const chats: Record<DAChatId, ExtractionStatus> = {};
      chats[payload] = ExtractionStatus.Done;
      Object.keys(state.chats).forEach(c => chats[c] = state.chats[c]);
      state.chats = chats;
      state.activeChat = payload;
    },
    deleteChatFromStore(state, { payload }: PayloadAction<DAChatId>) {
      delete state.chats[payload];
    },
    addDAUpload(state, { payload }: PayloadAction<FileUploadStats>) {
      state.uploads = {
        ...state.uploads,
        [payload.uploadid]: payload,
      };
    },
    removeUpload(
      state,
      { payload: { uploadid } }: PayloadAction<WithUploadId>
    ) {
      delete state.uploads[uploadid];
    },
    removeAllUploads(state) {
      state.uploads = {};
    },
    removeAllUploaded(state) {
      state.uploaded = {};
    },
  },
});

export const {
  setChats,
  setActiveChat,
  deleteChatFromStore,
  addDAUpload,
  setUploadProgress,
  removeUpload,
  removeAllUploads,
  removeAllUploaded,
  addChat,
  setChatStatus,
} = chatSlice.actions;

export const chatSelector = (state: RootState): IChatState => state.chat;

export const selectChats = createSelector(
  chatSelector,
  (chat) => chat.chats
);
export const selectActiveChat = createSelector(
  chatSelector,
  (chat) => chat.activeChat
);
export const selectDAUploads = createSelector(chatSelector, (chat) =>
  chat.uploads === undefined ? [] : Object.values(chat.uploads)
);
