import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios, { Axios, Canceler } from 'axios';
// import { useTranslation } from 'react-i18next';
import { Store } from 'react-notifications-component';

import { extractTable, ProgressionHandler } from '../../services/file-upload';
import { createid } from '../../services/id';
import { RootState } from '../store';
import {
  ITableExtractorUploadState,
  ProgressActionPayload,
  TableExtractorBox,
  TableExtractorBoxId,
  TableExtractorFileUploadError,
  TableExtractorFileUploadStats,
  WithTableExtractorBoxId,
  WithUploadId,
} from './te-upload.types';
import { FileType, UploadId } from './upload.types';

export const tableExtractorUploadThunk = createAsyncThunk<
  void,
  { file: File; boxid?: string },
  {
    state: RootState;
    rejectValue: string;
  }
>('uploadTableExtrctorFile', async ({ file, boxid }, { dispatch }) => {
  const { name, size } = file;
  const type = name.split('.').pop()?.toLowerCase() as FileType;
  const uploadid = createid();

  // dispatch(addUpload({ uploadid, name, size, progress: 0, type, file }));
  // Adding a slight delay in the processing of the file to avoid error: `Uncaught Invariant Violation: Cannot call hover while not dragging.,`
  await new Promise((res) => setTimeout(res, 0));
  dispatch(
    addUploaded({
      name,
      uploadid,
      size,
      type,
      id: uploadid,
      boxid,
      file,
      progress: 0,
    })
  );
  void dispatch(recentlyUploadedThunk({ uploadid }));
});

export const recentlyUploadedThunk = createAsyncThunk<
  void,
  { uploadid: UploadId }
>('recentlyUploadedThunk', async ({ uploadid }, { dispatch }) => {
  dispatch(addRecentlyUploaded(uploadid));
  await new Promise((res) => setTimeout(res, 4_000));
  dispatch(removeRecentlyUploaded(uploadid));
});

export const initialState: ITableExtractorUploadState = {
  uploadsError: {},
  tableExtractorBoxes: {},
  recentlyUploaded: [],
  isExtracting: false,
};

export const tableExtractorFilesThunk = createAsyncThunk<
  boolean,
  { axios: Axios },
  {
    state: RootState;
    rejectValue: string;
  }
>('tableExtractorFiles', async ({ axios: _axios }, { dispatch, getState }) => {
  const state = getState();
  const boxes = selectTableExtractorBoxesWithContent(state);
  let onlyFails = true;
  for (const { boxid, uploads } of boxes) {
    const files = Object.values(uploads);
    await Promise.all(
      files.map((media) =>
        dispatch(tableExtractorSingleFileThunk({ axios: _axios, media, boxid }))
      )
    ).then((response) => {
      onlyFails = response.every(i => i.payload !== undefined)
    });
  }
  await dispatch(cleanBoxesThunk());
  return !onlyFails;
});

export const tableExtractorSingleFileThunk = createAsyncThunk<
  void,
  { axios: Axios; media: TableExtractorFileUploadStats; boxid: string },
  {
    state: RootState;
    rejectValue: string;
  }
>(
  'tableExtractorSingleFileThunk',
  async ({ axios: _axios, media, boxid }, { dispatch, rejectWithValue }) => {
    const { uploadid, file } = media;
    const onProgress: ProgressionHandler = (progress) =>
      dispatch(setUploadProgress({ progress, uploadid, boxid }));
    const { cancel, promise } = extractTable(file, onProgress, _axios);

    dispatch(setCancel({ uploadid, cancel, boxid }));

    try {
      await promise;
      dispatch(removeUploaded({ uploadid, boxid }));
    } catch (e) {
      if (axios.isCancel(e)) return;
      // TODO: get kind of possible error
      const { name, size } = file;
      const type = name.split('.').pop()?.toLowerCase() as FileType;
      if (e instanceof Error) {
        dispatch(
          addUploadError({
            uploadid,
            errorCodes: [e.message],
            name,
            size,
            type,
          })
        );
        dispatch(removeUploaded({ uploadid, boxid }));
        // dispatch(removeUploadError({ uploadid })); // remove le file avec l'erreur de la page d'upload
        // eslint-disable-next-line
        Store.addNotification({
          title: `Your document ${name} has failed`,
          message: `${e.message}`,
          type: 'danger',
          container: 'bottom-right',
          animationIn: ['animate__animated animate__fadeIn'],
          animationOut: ['animate__animated animate__fadeOut'],
          dismiss: {
            duration: 10000,
            onScreen: true,
            pauseOnHover: true,
            showIcon: true,
          },
        });
        return rejectWithValue("error")
      } else {
        console.log('Unexpected error', e);
      }
    }
    dispatch(removeUploaded({ uploadid, boxid }));
  }
);

export const cleanBoxesThunk = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
    rejectValue: string;
  }
>('cleanBoxes', (_, { dispatch, getState }) => {
  const boxes = selectTableExtractorBoxes(getState());
  for (const { boxid, uploads } of boxes) {
    const files = Object.values(uploads);
    if (!files.length) dispatch(removeTableExtractorBox({ boxid }));
  }
});

export const tableExtractorUploadSlice = createSlice({
  name: 'tableExtractorUpload',
  initialState,
  reducers: {
    addUploadError(
      state,
      { payload }: PayloadAction<TableExtractorFileUploadError>
    ) {
      state.uploadsError[payload.uploadid] = payload;
    },
    removeUploadError(
      state,
      { payload: { uploadid } }: PayloadAction<WithUploadId>
    ) {
      delete state.uploadsError[uploadid];
    },
    setCancel(
      state,
      {
        payload: { uploadid, cancel, boxid },
      }: PayloadAction<
        { cancel: Canceler } & WithUploadId & WithTableExtractorBoxId
      >
    ) {
      const uploaded = state.tableExtractorBoxes[boxid]?.uploads[uploadid];
      if (!uploaded) return;
      uploaded.cancel = cancel;
    },
    setUploadProgress(
      state,
      {
        payload: { uploadid, progress, boxid },
      }: PayloadAction<ProgressActionPayload & WithTableExtractorBoxId>
    ) {
      const uploaded = state.tableExtractorBoxes[boxid]?.uploads[uploadid];
      if (!uploaded) return;
      uploaded.progress = progress;
    },
    addUploaded(
      state,
      {
        payload,
      }: PayloadAction<
        TableExtractorFileUploadStats & { boxid?: TableExtractorBoxId }
      >
    ) {
      const { boxid, ...file } = payload;
      const { uploadid } = file;
      const transcriptionBox = boxid
        ? state.tableExtractorBoxes[boxid]
        : Object.values(state.tableExtractorBoxes).find((box) => box.uploads);

      if (transcriptionBox) {
        transcriptionBox.uploads[uploadid] = {
          ...file,
        };
      } else {
        const id = createid();
        state.tableExtractorBoxes[id] = {
          boxid: id,
          uploads: { [uploadid]: file },
        };
      }
    },
    removeUploaded(
      state,
      {
        payload: { uploadid, boxid },
      }: PayloadAction<WithUploadId & WithTableExtractorBoxId>
    ) {
      const box = state.tableExtractorBoxes[boxid];
      if (box) delete box.uploads[uploadid];
    },
    addRecentlyUploaded(state, { payload }: PayloadAction<UploadId>) {
      state.recentlyUploaded.push(payload);
    },
    removeRecentlyUploaded(state, { payload }: PayloadAction<UploadId>) {
      const index = state.recentlyUploaded.indexOf(payload);
      if (index !== -1) state.recentlyUploaded.splice(index, 1);
    },
    addTableExtractorBox(
      state,
      { payload }: PayloadAction<TableExtractorBox | undefined>
    ) {
      const box = payload || {
        boxid: createid(),
        language: undefined,
        uploads: {},
      };
      state.tableExtractorBoxes[box.boxid] = box;
    },
    removeTableExtractorBox(
      state,
      { payload: { boxid } }: PayloadAction<WithTableExtractorBoxId>
    ) {
      delete state.tableExtractorBoxes[boxid];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(tableExtractorFilesThunk.fulfilled, (state) => {
      state.isExtracting = false;
    });
    builder.addCase(tableExtractorFilesThunk.pending, (state) => {
      state.isExtracting = true;
    });
    builder.addCase(tableExtractorFilesThunk.rejected, (state) => {
      state.isExtracting = false;
    });
  },
});

export const {
  addUploadError,
  removeUploadError,
  setCancel,
  setUploadProgress,
  addUploaded,
  removeUploaded,
  addRecentlyUploaded,
  removeRecentlyUploaded,
  addTableExtractorBox,
  removeTableExtractorBox,
} = tableExtractorUploadSlice.actions;

export const tableExtractorUploadSelector = (
  state: RootState
): ITableExtractorUploadState => state.tableExtractorUpload;

export const selectUploadsError = createSelector(
  tableExtractorUploadSelector,
  (tableExtractorUpload) => Object.values(tableExtractorUpload.uploadsError)
);

export const selectTableExtractorBoxes = createSelector(
  tableExtractorUploadSelector,
  (tableExtractorUpload) =>
    Object.values(tableExtractorUpload.tableExtractorBoxes)
);

export const selectHasUploads = createSelector(
  [selectTableExtractorBoxes, selectUploadsError],
  (boxes, errors) => boxes.length || errors.length
);

export const selectTableExtractorBoxesWithContent = createSelector(
  selectTableExtractorBoxes,
  (boxes) => boxes.filter((box) => Object.values(box.uploads).length > 0)
);

export const selectHasValidTableExtractions = createSelector(
  selectTableExtractorBoxesWithContent,
  (boxes) => {
    return boxes.some((box) => !!box.uploads);
  }
);

export const selectIsExtracting = createSelector(
  tableExtractorUploadSelector,
  (state) => state.isExtracting
);

export const selectRecentlyUploaded = createSelector(
  tableExtractorUploadSelector,
  (state) => state.recentlyUploaded
);
