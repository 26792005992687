import { Canceler } from 'axios';

import { Language } from '../../services/translation';

export enum FileType {
  DOC = 'doc',
  DOCX = 'docx',
  HTML = 'html',
  TXT = 'txt',
  XML = 'xml',
  XLS = 'xls',
  XLSX = 'xlsx',
  PPT = 'ppt',
  PPTX = 'pptx',
  PDF = 'pdf',
  AAC = 'aac',
  AMR = 'amr',
  FLAC = 'flac',
  M4A = 'm4a',
  MOV = 'mov',
  MP3 = 'mp3',
  MP4 = 'mp4',
  MPEG = 'mpeg',
  OGG = 'ogg',
  WAV = 'wav',
  ODS = 'ods',
  ODP = 'odp',
  ODT = 'odt',
}

export enum FileUploadWarning {
  NOT_SUPPORTED = 'Document type not supported',
  TOO_BIG = 'File is too big',
  WRONG_LANGUAGE = 'Language is not supported',
  TOO_MANY_CHARACTERS = 'Too many characters',
  NONE = '',
}

export interface FileUploadResult {
  id: string;
  language: Language;
  length: number;
  type: string;
  warning: FileUploadWarning; // ex: 'Document type not supported'
}

export interface FileTranslateResult {
  id: string;
}

/* UPLOAD */

export type UploadId = string;

export interface WithUploadId {
  uploadid: UploadId;
}

interface FileUpload extends WithUploadId {
  name: string;
  size: number;
  type: FileType;
}

export interface FileUploadStats extends FileUpload {
  cancel?: Canceler;
  progress: number;
}

export interface FileUploaded extends FileUpload {
  id: string;
  langDetected: Language;
  langTargets: Language[];
}

export interface FileUploadError extends FileUpload {
  errorCodes: string[];
}

export interface ProgressActionPayload extends WithUploadId {
  progress: number;
}

/* TranslationBox */

export type TranslationBoxId = string;

export interface WithTranslationBoxId {
  boxid: TranslationBoxId;
}

export interface TranslationBox extends WithTranslationBoxId {
  detected: Language | undefined;
  source: Language | undefined;
  uploads: Record<UploadId, FileUploaded>;
  applyToAll?: Language[];
}

/* STATE */

export interface IUploadState {
  uploads: Record<UploadId, FileUploadStats>;
  uploadsError: Record<UploadId, FileUploadError>;
  translationBoxes: Record<TranslationBoxId, TranslationBox>;
  isTranslating: boolean;
  recentlyUploaded: UploadId[];
}
